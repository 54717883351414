import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Img from 'gatsby-image'
import * as Icon from 'react-feather'
import { Helmet } from 'react-helmet'

class IndexPage extends React.PureComponent {
  render() {
    const App = () => {
      return <Icon.Camera />
    }
    return (
      <Layout>
        <Helmet>
          <title>Micha Wiebe - UX Designer from Hamburg</title>
          <meta
            charSet="utf-8"
            content="width=device-width, initial-scale=1 maximum-scale=1.0, user-scalable=no"
            name="viewport"
          />
          <meta
            name="description"
            content="Hey, I'm Micha Wiebe - UX Designer based in Hamburg and founder of Sketch Wiki. I specialize in product design, user research and data analysis."
          />
          <meta
            name="keywords"
            content="Micha Wiebe, UX Design, Sketch Wiki, Designer in Hamburg"
          />
          
    <meta name="google-site-verification" content="XsuBt3opwuUfNNgwqfPHOi409XalTInnzngRtXWtgno" />
        </Helmet>
        <script src="https://cdn.jsdelivr.net/npm/feather-icons/dist/feather.min.js" />
        <script src="/src/JS/animations.js" />

        <body>
          <img
            className="img Hamburg-Image"
            alt="Hamburg Header"
            src={require('../images/Hamburg-photo.png')}
          />
          <div className="flex">
            <div className="Hero Background" />
          </div>
          <div className="Hero Hamburg-Image Transparent">
            <img
              className="img Hamburg-Image big-screen"
              alt="Hamburg Header"
              src={require('../images/Hamburg-photo.png')}
            />
            <div className="Location one-skill ">
              <Icon.MapPin color="#878e88" size={14} />
              <a className="Location">Hamburg, Germany</a>
            </div>
            <div className="HeroGroup first-tile">
              <img
                className="face"
                alt="Micha Wiebe Profile"
                src={require('../images/Micha-Wiebe.png')}
              />
              <h1>Welcome!</h1>
              <p>
                I'm Micha Wiebe, a <strong>UX specialist</strong>{' '}
                currently working for Barclaycard in Hamburg, Germany. For 4 years I design{' '}
                <strong>digital products</strong> like apps, websites and the
                software that supports them.
              </p>
              <div>
                <a
                  className="link-button"
                  href="https://dribbble.com/micha-design"
                  data-wpel-link="external"
                  target="_blank"
                  rel="nofollow noopener external noreferrer"
                >
                  Portfolio
                </a>
                <a
                  className="link-button secondary"
                  href="https://linkedin.com/in/micha-design"
                  data-wpel-link="external"
                  target="_blank"
                  rel="nofollow noopener external noreferrer"
                >
                  Contact
                </a>
              </div>
            </div>
          </div>
          <div className="Skills">
            <div className="no-tile">
              <h2 className="my-skills">Skills and Tools</h2>
              <p className="subline-text">What I consider myself good at</p>
            </div>
            <div className="all-skills">
              <div className="one-skill">
                <Icon.Edit color="#031317" size={18} />
                <p className="skill">UX Conception</p>
                {/* <p className="skill-tag top">Top Skill</p> */}
              </div>
              <div className="one-skill">
                <Icon.Paperclip color="#031317" size={18} />
                <p className="skill">Sketch</p>
              </div>
              <div className="one-skill">
                <Icon.Eye color="#031317" size={18} />
                <p className="skill">User Research</p>
              </div>
              <div className="one-skill">
                <Icon.BarChart2 color="#031317" size={18} />
                <p className="skill">Data Analysis</p>
              </div>
              <div className="one-skill">
                <Icon.Edit3 color="#031317" size={18} />
                <p className="skill">UX Writing</p>
              </div>
              <div className="one-skill">
                <Icon.Heart color="#031317" size={18} />
                <p className="skill">Product Design</p>
              </div>
              <div className="one-skill">
                <Icon.Paperclip color="#031317" size={18} />
                <p className="skill">Zeplin</p>
              </div>

              <div className="one-skill">
                <Icon.Grid color="#031317" size={18} />
                <p className="skill">Wireframes</p>
              </div>
              <br className="media-425" />
              <div className="one-skill">
                <Icon.Globe color="#031317" size={18} />
                <p className="skill">SEO</p>
              </div>
              <div className="one-skill ">
                <Icon.GitBranch color="#031317" size={18} />
                <p className="skill">Git</p>
                <p className="skill-tag new">New</p>
              </div>
              <div className="one-skill">
                <Icon.Code color="#031317" size={18} />
                <p className="skill">HTML</p>
              </div>
              <div className="one-skill">
                <Icon.Code color="#031317" size={18} />
                <p className="skill">CSS</p>
              </div>
              <div className="one-skill">
                <Icon.Globe color="#031317" size={18} />
                <p className="skill">Wordpress</p>
              </div>
              <div className="one-skill">
                <Icon.Heart color="#031317" size={18} />
                <p className="skill">User-Centered Design</p>
              </div>
              <div className="one-skill">
                <Icon.Heart color="#031317" size={18} />
                <p className="skill">Prototyping</p>
              </div>
              <div className="one-skill">
                <Icon.Paperclip color="#031317" size={18} />
                <p className="skill">Principle</p>
              </div>
              <div className="one-skill">
                <Icon.Users color="#031317" size={18} />
                <p className="skill">Jira</p>
              </div>
              <div className="one-skill">
                <Icon.Paperclip color="#031317" size={18} />
                <p className="skill">InVision</p>
              </div>
            </div>
          </div>
          <div className="Hero second-tile">
            <div className="HeroGroup">
              <div className="text flex-tile">
                <h2>
                  About You
                  <a
                    className="external-link"
                    href="https://aboutyou.de"
                    data-wpel-link="external"
                    target="_blank"
                    rel="nofollow noopener external noreferrer"
                  >
                    <Icon.ExternalLink
                      color="#878e88"
                      size={18}
                      stroke-width="3"
                    />
                  </a>
                </h2>
                <p>
                  In 2017 I moved to Hamburg and joined About You to shape the
                  future of fashion eCommerce at one of Europes most exciting
                  tech companies - Hamburgs first Unicorn!
                </p>

                <h3>Stories</h3>
                <div>
                  <div className="article-box">
                    <a
                      className="link-invisible"
                      target="_blank"
                      rel="nofollow noopener external noreferrer"
                      href="https://medium.com/@micha.design/implementing-user-research-in-the-production-cycle-9b04c921008d"
                    >
                      <img
                        className="article-preview"
                        alt="User Research Routine"
                        src={require('../images/user-research.jpeg')}
                      />
                      <div className="article-contents">
                        <h4>Implementing a user research routine</h4>
                        <p>
                          After advocating for research for years, we now talk to users regulary.
                        </p>
                      </div>
                    </a>
                  </div>
                  <div className="article-box">
                    <a
                      className="link-invisible"
                      target="_blank"
                      rel="nofollow noopener external noreferrer"
                      href="https://medium.com/@micha.design/a-feature-in-review-express-checkout-4b7104ed52a9"
                    >
                      <img
                        className="article-preview"
                        alt="Express Checkout Feature Review"
                        src={require('../images/ec_dribbble.gif')}
                      />
                      <div className="article-contents">
                        <h4>A feature in review — Express Checkout</h4>
                        <p>
                          Spoiler: this feature could boost our conversion rate
                          like Amazon. This is how we built it.
                        </p>
                      </div>
                    </a>
                  </div>
                  <div className="article-box">
                    <a
                      className="link-invisible"
                      target="_blank"
                      rel="nofollow noopener external noreferrer"
                      href="https://uxdesign.cc/3-routines-that-made-me-a-better-ux-designer-185f314b9037"
                    >
                      <img
                        className="article-preview"
                        alt="Design, Business and Users drive User Experience"
                        src={require('../images/3-routines-ux.png')}
                      />
                      <div className="article-contents">
                        <h4>3 routines that made me a better UX designer</h4>
                        <p>
                          How UX brings business, design and users together.
                        </p>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="sketch-wiki-mobile">
                  <img
                    className="sketch-image ui-hover"
                    alt="Sketch Wiki on a MacBook floating"
                    src={require('../images/About-You-App-Mockup.png')}
                  />
                  <img
                    className="sketch-image ui-shadow"
                    alt="Sketch Wiki on a MacBook floating"
                    src={require('../images/Dropshadow-AY.png')}
                  />
                </div>
                {/* <p className="kpi-text counter" data-count="500">
                  12GB
                </p>
                <p className="no-margin">in Sketch files</p> */}
              </div>
              <div className="sketch-wiki-scroll">
                <span>
                  <img
                    className="sketch-image ui-hover"
                    alt="Sketch Wiki on a MacBook floating"
                    src={require('../images/About-You-App-Mockup.png')}
                  />
                  <img
                    className="sketch-image ui-shadow"
                    alt="Sketch Wiki on a MacBook floating"
                    src={require('../images/Dropshadow-AY.png')}
                  />
                </span>
              </div>
            </div>
          </div>
         
          <div className="Skills">
            <div className="no-tile">
              <h2 className="my-skills">Working methods</h2>
              <p className="subline-text">Some best practices I learned</p>
            </div>
            <div className="all-skills methods">
              <div className="one-method">
                <Icon.HelpCircle color="#031317" size={36} />
                <h3>Analyze the reason why we want this</h3>
                <p>
                  To find a good answer to a UX problem you need to understand
                  the goal before the solution. Only this way users,
                  stakeholders and designers really get what they want.
                </p>
              </div>
              <div className="one-method">
                <Icon.Link color="#031317" size={36} />
                <h3>Connect accross disciplines and tools</h3>
                <p>
                  When I design, I harness the synergy of multiple tools,
                  backgrounds and past experiences. For better solutions I like
                  to work interdisciplinary and across cultures.
                </p>
              </div>
              <div className="one-method">
                <Icon.Loader color="#031317" size={36} />
                <h3>Invent and brainstorm</h3>
                <p>
                  From what I learned the best features, performance and
                  integration win users. This is why re-imagening and inventing
                  new ideas are so important to succeed in design.
                </p>
              </div>
              <div className="one-method">
                <Icon.Repeat color="#031317" size={36} />
                <h3>Test and interate with users</h3>
                <p>
                  Even with short timings and no budget I need to test with real
                  humans. To see design through the users eyes makes all the
                  difference between mediocre and marvelous products.
                </p>
              </div>
            </div>
          </div>
          <div className="Hero second-tile">
            <div className="HeroGroup">
              <div className="text flex-tile">
                <h2>
                  Sketch Wiki
                  <a
                    className="external-link"
                    href="https://sketch-wiki.de"
                    data-wpel-link="external"
                    target="_blank"
                    rel="nofollow noopener external noreferrer"
                  >
                    <Icon.ExternalLink
                      color="#878e88"
                      size={18}
                      stroke-width="3"
                    />
                  </a>
                </h2>

                <p>
                  With the first posts dating back in 2017, Sketch Wiki is the
                  first and most comprehensive website to learn Sketch in
                  german. I share easy-to-read manuals for the tool itself,
                  plugins and digital design basics with the community.
                </p>
                {/* <h3>Stories</h3>
                <div>
                  <div className="article-box">
                    <a
                      className="link-invisible"
                      target="_blank"
                      rel="nofollow noopener external noreferrer"
                      href="https://sketch-wiki.de/updates/sketch-53"
                    >
                      <img
                        className="article-preview"
                        alt="Sketch 53 Update Review on Sketch Wiki"
                        src={require('../images/Sketch-53.png')}
                      />
                      <div className="article-contents">
                        <h4>Sketch 53</h4>
                        <p>
                          Das Update vom 5. Februar 2019 bringt einen neuen
                          Farbwähler und komplexere Symbol Overrides.
                        </p>
                      </div>
                    </a>
                  </div>
                  <div className="article-box">
                    <a
                      className="link-invisible"
                      target="_blank"
                      rel="nofollow noopener external noreferrer"
                      href="https://sketch-wiki.de/updates/sketch-53"
                    >
                      <img
                        className="article-preview"
                        alt="Sketch Wiki looks at the changes in Sketch 52"
                        src={require('../images/Sketch-52.png')}
                      />
                      <div className="article-contents">
                        <h4>Sketch 52</h4>
                        <p>
                          Mehrere große Änderungen kommen in Sketch 52: UI
                          Redesign, Dark Mode, Daten-Plugin und vieles mehr...
                        </p>
                      </div>
                    </a>
                  </div>
                </div> */}
                <div className="sketch-wiki-mobile">
                  <img
                    className="sketch-image ui-hover"
                    alt="Sketch Wiki on a MacBook floating"
                    src={require('../images/MacBook-Sketch-Wiki.png')}
                  />
                  <img
                    className="sketch-image ui-shadow"
                    alt="Sketch Wiki on a MacBook floating"
                    src={require('../images/Dropshadow-SW.png')}
                  />
                </div>
                {/* <p className="kpi-text counter" data-count="500">
                  650+
                </p>
                <p className="no-margin">Monthly visitors</p> */}
              </div>
              <div className="sketch-wiki-scroll">
                <span>
                  <img
                    className="sketch-image ui-hover"
                    alt="Sketch Wiki on a MacBook floating"
                    src={require('../images/MacBook-Sketch-Wiki.png')}
                  />
                  <img
                    className="sketch-image ui-shadow"
                    alt="Sketch Wiki on a MacBook floating"
                    src={require('../images/Dropshadow-SW.png')}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="Skills">
            <div className="no-tile">
              <h2 className="my-skills">Side projects</h2>
              <p className="subline-text">
                Stuff I do and build in my free time
              </p>
            </div>
            <div className="all-skills tools">
              <div className="one-method tools">
                <img
                  className="activities"
                  alt="Micha Wiebe speaker at code.talks conference 2018"
                  src={require('../images/code-talks.png')}
                />
                <h3>UI/UX curator at code.talks conference</h3>
                <p>
                  In 2018 I joined code.talks as curator of the first UI/UX
                  track at this conference. I oragnized and selected few top
                  professionals in the industry to talk on topics like Design
                  Systems, Accessibility, Design Trends and Design+Code.
                </p>
              </div>
              <div className="one-method tools">
                <img
                  className="activities"
                  alt="News Aggregator made of Evernote, eMail and a few scripts"
                  src={require('../images/Autodiscover-tool.png')}
                />
                <h3>Design News Archive</h3>
                <p>
                  As a full-time employee you're definetly going to lose track
                  of every Medium Post on UX out there. I borrowed an idea by
                  Aarron Walter and build a design news aggregator using only
                  Evernote, eMail and let it sort by relevance.
                </p>
              </div>
              <div className="one-method tools">
                <img
                  className="activities"
                  alt="Podlove Community Project"
                  src={require('../images/podlove.png')}
                />
                <h3>Podlove</h3>
                <p>
                  The Podlove community develops an open-source podcast-suite,
                  but from time to time they lack in designers for their
                  features. When needed, I join for conception and design like
                  the Podlove Web Preview.
                </p>
              </div>
              <div className="one-method tools">
                <img
                  className="activities"
                  alt="Design System for writing consistently copytext"
                  src={require('../images/design-system-words.png')}
                />
                <h3>Design System for UX writing</h3>
                <p>
                  How can large organizations make sure that their communication
                  is consistent? In a conceptual approach I applied the idea of
                  Design Systems as living documents to a wording guide.
                </p>
              </div>
            </div>
          </div>
          <div className="Skills">
            <div className="no-tile">
              <p className="quote">So long, and thanks for all the fish!</p>
            </div>
          </div>
        </body>
      </Layout>
    )
  }
}

export default IndexPage
